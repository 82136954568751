import { useSelector } from 'react-redux';

import {
  selectConfig,
  selectOpenedCellData,
} from '../../store/slices/configSlice';
import { StackName } from '../../store/types/configResponse';
import Loader from '../Loader';
import BhPost10 from './components/BhPost10';
import BhScreenDb from './components/BhScreenDb/BhScreenDb';
import BhTerminalPure from './components/BhTerminalPure';
import Loundry2 from './components/Loundry2';
import Magyar5 from './components/Magyar5/Magyar5';
import Magyar9 from './components/Magyar9/Magyar9';
import UltraCommon from './components/UltraCommon';
import { useGetDeviceConfig } from './hooks/useGetDeviceConfig';
import { MOCKED_CELLS } from './mocks/cells';

import {
  PostamatContainer,
  PostamatHead,
  PostamatWrapper,
  StacksContainer,
} from './styled';

export default function Postamat(): JSX.Element {
  const deviceConfig = useSelector(selectConfig);
  const configStatus = useGetDeviceConfig();

  const openedCellData = useSelector(selectOpenedCellData);

  const isShowHighlightCell = !!deviceConfig && !!openedCellData;

  const shownData = isShowHighlightCell ? (
    deviceConfig.stacks.map(({ cells, stack: stackId, name }) => {
      const { cell, stack } = openedCellData;
      const isActiveStack = stack === stackId;

      const isSkipTemplate = cells.length <= 1;

      if (isSkipTemplate) return;

      if (
        name.includes(StackName.ultra17) ||
        name.includes(StackName.ultra18) ||
        name.includes(StackName.postuaUltraQpro) ||
        name.includes(StackName.postuaUltra18)
      ) {
        return (
          <UltraCommon
            key={name + stackId}
            isActiveStack={isActiveStack}
            activeCellId={cell}
            cells={cells}
          />
        );
      }
      if (
        name.includes(StackName.bhPost10) ||
        name.includes(StackName.laundryPost10)
      ) {
        return (
          <BhPost10
            key={name + stackId}
            isActiveStack={isActiveStack}
            activeCellId={cell}
            cells={cells}
          />
        );
      }
      if (
        name.includes(StackName.bhTerminalPure) ||
        name.includes(StackName.laundryTerminalPure)
      ) {
        return (
          <BhTerminalPure
            key={name + stackId}
            isActiveStack={isActiveStack}
            activeCellId={cell}
            cells={cells}
          />
        );
      }
      if (
        name.includes(StackName.loundry2) ||
        name.includes(StackName.bhLaundry)
      ) {
        return (
          <Loundry2
            key={name + stackId}
            isActiveStack={isActiveStack}
            activeCellId={cell}
            cells={cells}
          />
        );
      }
      if (name.includes(StackName.bhScreenDb)) {
        return (
          <BhScreenDb
            key={name + stackId}
            isActiveStack={isActiveStack}
            activeCellId={cell}
            cells={cells}
          />
        );
      }
      if (name.includes(StackName.magyarTerminal5)) {
        return (
          <Magyar5
            key={name + stackId}
            isActiveStack={isActiveStack}
            activeCellId={cell}
            cells={cells}
          />
        );
      }
      if (name.includes(StackName.magyarBase9)) {
        return (
          <Magyar9
            key={name + stackId}
            isActiveStack={isActiveStack}
            activeCellId={cell}
            cells={cells}
          />
        );
      }

      return (
        <UltraCommon
          key={name + stackId}
          isActiveStack={false}
          cells={MOCKED_CELLS}
        />
      );
    })
  ) : (
    <UltraCommon isActiveStack={false} cells={MOCKED_CELLS} />
  );

  return configStatus === 'loading' ? (
    <Loader />
  ) : (
    <PostamatContainer>
      <PostamatWrapper>
        <PostamatHead />
        <StacksContainer>{shownData}</StacksContainer>
      </PostamatWrapper>
    </PostamatContainer>
  );
}
